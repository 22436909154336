(function(d) {
  'use strict'
  const headings = ['h1', 'h2', 'h3', 'h4']
  const pagelinks = d.querySelectorAll('a');
  const forEach = [].forEach

  const idify = el => {
    forEach.call(el, hh => {
      if (!hh.id) {
        hh.id = hh.textContent.toLowerCase().replace(/[^0-9a-z- ]/g, '').replace(/\s+/g, '_')
      }
    })
  }

  if (headings) {
    forEach.call(headings, hh => {
      idify(d.querySelectorAll(hh))
    })
  }

  if (pagelinks) {

    forEach.call(pagelinks, el => {
      if (/(.pdf)$/.test(el.href)) {
        // console.log(el.href)
        el.setAttribute('aria-label', el.textContent)
        el.setAttribute('target', '_blank')
        el.setAttribute('rel', 'noopener noreferrer')
      }
    })
  }

})(document)
